function PlanButton({
  onClick,
  variant = "primary",
  planType,
  originalPrice,
  currentPrice,
  disabled = false,
  opacity = 1,
}) {
  const baseClasses =
    "font-semibold py-1 px-2 rounded-sm text-sm outline w-full";
  const variantClasses =
    variant === "primary"
      ? "bg-primary-600 text-black hover:bg-rombo-primary-600 text-black/90 outline-black/5"
      : "bg-black/80 text-white/90 hover:bg-black/90 outline-black/20";
  const disabledClasses = disabled ? "opacity-80 cursor-not-allowed" : "";
  const opacityClasses = `!opacity-${opacity}`;
  return (
    <button
      className={`${baseClasses} ${variantClasses} ${disabledClasses} ${opacityClasses}`}
      onClick={onClick}
      disabled={disabled}
    >
      <span className="!opacity-90 font-bold pr-1">{planType} plan</span>
      <span className="line-through !opacity-60">${originalPrice}</span> $
      {currentPrice}
    </button>
  );
}

export default PlanButton;
