import React from "react";
import triggerStripeCheckout from "../Helpers/stripeTrigger";
import PlanButton from "./PlanButton";

function PricingModal({ setPricingModal, appId, authEmail }) {
  const [isNotified, setIsNotified] = React.useState(false);

  return (
    <div
      onClick={() => setPricingModal(false)}
      className="w-full fixed inset-0 z-50 flex items-center justify-center"
    >
      <div className="absolute inset-0 bg-black opacity-80"></div>

      <div
        onClick={(e) => e.stopPropagation()}
        className="relative bg-white w-3/4 rounded-md border border-black/10"
      >
        <div className="text-center items-center flex flex-col items-start">
          <div className="absolute top-4 right-4 text-black/60 hover:text-black/80 cursor-pointer">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="14"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path d="M18 6 6 18" />
              <path d="m6 6 12 12" />
            </svg>
          </div>

          <div className="flex w-full p-8 space-x-4">
            <div class="flex flex-col gap-3">
              {" "}
              <div class="flex gap-5 md:flex-row flex-col">
                {" "}
                <div class="w-full text-left md:w-fit text-sm bg-primary font-semibold h-fit border p-4 border-rombo-primary rounded-lg bg-primary-100 outline outline-2 outline-black/5">
                  {" "}
                  <div class="flex items-center align-center justify-between mb-2">
                    {" "}
                    <h3 class="bg-rombo-primary-400 rounded-sm w-fit text-lg py-0.5">
                      Pro
                    </h3>{" "}
                  </div>{" "}
                  <p>
                    ★ <strong>Create custom animations:</strong> with our Chrome
                    Extension, you can now create custom animations directly on
                    your localhost project.
                  </p>
                  <p>
                    ★ <strong>Save Animations Presets to the library:</strong>{" "}
                    save custom animations for reuse on other projects — no more
                    copy-pasting @keyframes
                  </p>
                  <p>
                    <strong>★ Apply saved animations in Figma </strong>
                  </p>{" "}
                  <div className="mt-4 flex flex-col gap-2">
                    <PlanButton
                      onClick={() =>
                        triggerStripeCheckout(appId, authEmail, "monthly")
                      }
                      variant="primary"
                      planType="Monthly"
                      originalPrice="19"
                      currentPrice="9/month"
                    />
                    <PlanButton
                      onClick={() =>
                        triggerStripeCheckout(appId, authEmail, "lifetime")
                      }
                      variant="primary"
                      planType="Lifetime"
                      originalPrice="199"
                      currentPrice="99"
                    />
                  </div>
                </div>
                <div class="w-full text-left md:w-fit text-sm bg-primary font-semibold h-fit border p-4 border-rombo-primary rounded-lg bg-white outline outline-2 outline-black/5">
                  {" "}
                  <div class="flex items-center align-center justify-between mb-2">
                    {" "}
                    <h3 class="bg-rombo-primary-400 rounded-sm text-lg w-fit py-0.5">
                      Team{" "}
                      <span className="text-xs opacity-60 uppercase">
                        coming soon
                      </span>
                    </h3>{" "}
                  </div>{" "}
                  <p>
                    <em>All of Pro, and…</em>
                  </p>
                  <p>
                    <strong>
                      ★ Collaborate with up to five users in Rombo's extension
                    </strong>
                  </p>
                  <p>
                    ★ <strong>Organization motion library:</strong> Share
                    animations with your team (or across agency clients)—enable
                    designers, engineers and marketers to reuse motion effects.
                  </p>
                  <p>
                    ★ <strong>Automatically create your motion library:</strong>{" "}
                    we scrape and ingest all of your design and web assets to
                    automagically create a motion library
                  </p>
                  <p>
                    <strong>★ Rombo Team builds your library: </strong>1 day, 1
                    week, 1 month sprint with the Rombo team to build your
                    reusable custom motion library
                  </p>{" "}
                  <div className="mt-4 flex flex-col gap-2">
                    <PlanButton
                      variant="secondary"
                      planType="Monthly"
                      originalPrice="89"
                      currentPrice="39/month"
                      disabled={true}
                    />
                    <PlanButton
                      variant="secondary"
                      planType="Lifetime"
                      originalPrice="799"
                      currentPrice="399"
                      disabled={true}
                    />
                    <button
                      className="mt-2 text-sm font-semibold text-black/70 hover:text-black/90 bg-slate-50 p-2 rounded-sm"
                      onClick={() => {
                        if (!isNotified) {
                          SubmitEmail(authEmail, "team")
                            .then(() => {
                              setIsNotified(true);
                            })
                            .catch(() => {
                              alert(
                                "Sorry, there was an error submitting your email. Please try again later."
                              );
                            });
                        }
                      }}
                    >
                      {isNotified
                        ? "You'll be notified! ✓"
                        : "Notify me when available"}
                    </button>
                  </div>
                </div>{" "}
              </div>{" "}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

async function SubmitEmail(email, platform) {
  const response = await fetch(
    "https://be51yaahwe.execute-api.eu-west-1.amazonaws.com/dev/waitlist",
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email, platform }),
    }
  );
  if (response.status === 200) {
    console.log("Email submitted");
    console.log(email);
  } else {
    throw new Error("Error submitting email");
  }
}

export default PricingModal;
