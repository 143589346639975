import React, { useState, useMemo, useEffect, useRef } from "react";
import { useMutation, gql } from "@apollo/client";
import "./Cloner.css";
import { useParams } from "react-router";
import { Button } from "../Core/Button";
import { nanoid } from "nanoid";
import useSWR from "swr";
import { GET_APP_ANIMATIONS } from "../../Queries";
import { ADD_ANIMATION, REMOVE_ANIMATION } from "../../Mutations";
import toast from "react-hot-toast";
import clsx from "clsx";

let randomCode = nanoid();

function Cloner({
  cloned_url,
  app_url,
  modalState,
  element,
  billing_plan,
  existingAnimations,
  platform,
  setWindowModal,
}) {
  const { app_id } = useParams();

  const [event, storeEvent] = useState(null);
  const [addMutation, addingAnimation] = useMutation(ADD_ANIMATION);
  const [removeMutation, removingAnimation] = useMutation(REMOVE_ANIMATION);
  const [openModal, setOpenModal] = useState(modalState);
  const [expanded, setExpanded] = useState(false);
  const [url, setUrl] = useState(
    cloned_url && !cloned_url.includes("http")
      ? `http://${cloned_url}?rombo=${randomCode}`
      : `${cloned_url}?rombo=${randomCode}`
  );

  const [inputUrl, setInputUrl] = useState(app_url || "");
  const [isCloning, setIsCloning] = useState(false);

  const styles = {
    expanded: {
      marginTop: "0%",
      width: "100%",
      height: "100%",
      marginLeft: "0%",
    },
    minimized: {
      marginTop: "2.5%",
      width: "95%",
      height: "96%",
      marginLeft: "2.5%",
    },
  };

  const toggleExpand = () => {
    setExpanded(!expanded);
  };

  const iframeElement = useRef();

  async function eventReceiver(event) {
    if (event.data && cloned_url) {
      if (event.data.source !== "rombo-animator") return;

      //
      if (event.data.data?.code?.includes(randomCode)) {
        event.source.postMessage("begin", url);
        event.source.postMessage(`${billing_plan}_plan`, url);
        if (element) {
          let stringified = JSON.stringify({ element });
          event.source.postMessage(stringified, url);
        }
        return;
      }
      return storeEvent(event.data);
    }
  }

  async function addAnimation({
    animation,
    element,
    text,
    splitter,
    direction,
    amount,
    clip,
    speed,
    delay,
    trigger,
    page,
    css,
    billing_plan,
  }) {
    try {
      if (element && animation) {
        let variables = {
          app_id,
          animation,
          element,
          text,
          splitter,
          direction,
          clip,
          animation_speed: speed,
          trigger,
          animation_delay: delay,
          animation_amount: parseInt(amount),
          page,
          css,
          billing_plan,
        };

        await addMutation({
          variables: variables,
          refetchQueries: [
            {
              query: GET_APP_ANIMATIONS,
              variables: { app_id: app_id },
            },
          ],
        });
      }
    } catch (e) {
      toast.error("Failed adding animation");
      console.log(e, "Failed adding animation");
    }
  }

  useEffect(() => {
    if (element !== null) setOpenModal(true);
  }, [element]);

  useEffect(() => {
    try {
      window.addEventListener("message", eventReceiver, false);
    } catch (e) {
      console.log(e, "Failed adding listener");
    }
  }, [cloned_url]);

  useEffect(() => {
    if (event && event !== null) {
      const { animation_data, action } = event.data;

      if (animation_data && JSON.parse(animation_data)) {
        const { element } = JSON.parse(animation_data);
        if (action === "delete") {
          removeMutation({
            variables: {
              app_id,
              element,
            },
            refetchQueries: [
              {
                query: GET_APP_ANIMATIONS,
                variables: { app_id: app_id },
              },
            ],
          });
          toast.success("Removed animation");
        } else if (action === "add") {
          addAnimation({ ...JSON.parse(animation_data) });
          toast.success("Added animation");
        }
      }
    }
  }, [event]);

  const openPopup = (url) => {
    // Use destructuring to get screen width and height
    const { width: screenWidth, height: screenHeight } = window.screen;

    // Calculate the desired width and height as 80% of the screen's dimensions
    const width = screenWidth * 0.8;
    const height = screenHeight * 0.8;

    // Calculate the left and top offsets to center the new window
    const left = (screenWidth - width) / 2;
    const top = (screenHeight - height) / 2;

    // Configure the window properties for "popup mode" using template literals
    const windowFeatures = `
        width=${width},
        height=${height},
        left=${left},
        top=${top},
        directories=no,
        location=no,
        menubar=no,
        resizable=yes,
        scrollbars=yes,
        status=no,
        toolbar=no
    `;

    // Open the new window in popup mode
    window.open(url, "newWindow", windowFeatures.trim());
  };

  function iframeLoaded(e) {
    try {
      let spawned = iframeElement?.current
        ? iframeElement?.current?.contentWindow
        : null;
      if (!spawned) {
        console.log("Failed to load iframe?");
        setOpenModal(false);
        return openPopup(url);
      }

      if (element) {
        spawned.postMessage(JSON.stringify({ element }), url);
      }
    } catch (e) {
      console.log("Failed to load iframe", e);
      setOpenModal(false);
      return openPopup(url);
    }
  }

  const handleClone = async () => {
    if (!inputUrl) return;

    setIsCloning(true);
    try {
      const response = await fetch(
        "https://snt26ame91.execute-api.eu-west-2.amazonaws.com/dev/clone",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            url: inputUrl,
            app_id: app_id,
          }),
        }
      );

      if (!response.ok) throw new Error("Failed to clone");

      const data = await response.json();
      setUrl(data.url);
      toast.success("Successfully cloned website");
    } catch (error) {
      console.error("Clone failed:", error);
      toast.error("Failed to clone website");
    } finally {
      setIsCloning(false);
    }
  };

  if (!cloned_url) {
    return (
      <div className="flex flex-col bg-slate-100 h-full overflow-hidden relative px-3 justify-between font-semibold pt-2 shadow-md outline outline-4 outline-slate-100 shadow-neutral-300 rounded-lg border border-solid border-dark-300">
        <div className="flex flex-col items-center gap-4 p-4">
          <h2 className="font-semibold">Enter the URL you want to clone</h2>
          <input
            type="text"
            value={inputUrl}
            onChange={(e) => setInputUrl(e.target.value)}
            className="w-full max-w-md px-3 py-2 border rounded"
            placeholder="https://example.com"
          />
          <button
            onClick={handleClone}
            disabled={isCloning}
            className="px-4 py-2 bg-primary-500 text-white rounded hover:bg-primary-600"
          >
            {isCloning ? "Cloning..." : "Clone Website"}
          </button>
        </div>
      </div>
    );
  }

  return (
    <>
      {openModal && (
        <div class="animator-holder delayed-fade-animation">
          <div
            style={expanded ? styles.expanded : styles.minimized}
            class="flex flex-col w-full h-full animator-window"
          >
            <header class="flex h-10 py-4 w-full flex-row items-center justify-between">
              <div class="w-full flex flex-row items-center justify-start">
                <button
                  class="mr-0.5 !bg-red-300 !text-red-900 !border-red-400 rounded-full p-1"
                  onClick={() => setOpenModal(false)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="12"
                    height="12"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="3"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-x"
                  >
                    <line x1="18" y1="6" x2="6" y2="18" />
                    <line x1="6" y1="6" x2="18" y2="18" />
                  </svg>
                </button>
                <button
                  class="mr-0.5 !bg-orange-200 !text-orange-900 !border-orange-400 rounded-full p-1"
                  onClick={toggleExpand}
                >
                  {expanded ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="12"
                      height="12"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="3"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="feather feather-minimize-2"
                    >
                      <polyline points="4 14 10 14 10 20" />
                      <polyline points="20 10 14 10 14 4" />
                      <line x1="14" y1="10" x2="21" y2="3" />
                      <line x1="3" y1="21" x2="10" y2="14" />
                    </svg>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="12"
                      height="12"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="3"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="feather feather-maximize-2"
                    >
                      <polyline points="15 3 21 3 21 9" />
                      <polyline points="9 21 3 21 3 15" />
                      <line x1="21" y1="3" x2="14" y2="10" />
                      <line x1="3" y1="21" x2="10" y2="14" />
                    </svg>
                  )}
                </button>
                <button
                  class=" !bg-green-200 !text-green-900 !border-green-400 rounded-full p-1"
                  onClick={() => setUrl(url + "?reset=true")}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="12"
                    height="12"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="3"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-refresh-ccw"
                  >
                    <polyline points="1 4 1 10 7 10" />
                    <polyline points="23 20 23 14 17 14" />
                    <path d="M20.49 9A9 9 0 0 0 5.64 5.64L1 10m22 4l-4.64 4.36A9 9 0 0 1 3.51 15" />
                  </svg>
                </button>
                <span class="px-2 opacity-50 cursor-not-allowed">
                  {cloned_url}
                </span>
                <span
                  class="cursor-pointer opacity-50 rounded-sm hover:opacity-90"
                  onClick={() => window.open(cloned_url)}
                >
                  ↗
                </span>
              </div>
            </header>
            <iframe
              title={url}
              ref={iframeElement}
              src={url}
              onLoad={iframeLoaded}
            ></iframe>
          </div>
        </div>
      )}
      <div className="flex items-center delayed-fade-animation-2 flex-col h-full overflow-hidden relative px-3 font-semibold pt-6 rounded-lg  ">
        {!existingAnimations ? (
          <>
            <h2 className="font-semibold text-lg">
              Animate your{" "}
              <span className="font-normal">
                {app_url?.replace("https://", "")}
              </span>{" "}
              sandbox
            </h2>{" "}
            <p className="text-md w-1/2 text-center font-thin py-1">
              We made a copy of your website and installed Rombo. Now you can
              play around with animations, with no fear!
            </p>
          </>
        ) : (
          <h2 className="font-semibold text-lg">
            Animate more animations to your
            <span className="px-1 font-normal">
              {app_url?.replace("https://", "")}
            </span>{" "}
            sandbox
          </h2>
        )}
        <br />
        <div className="flex w-[480px] items-center align-center justify-around">
          <button
            className={clsx(
              "font-semibold  px-3 py-1 shadow-lg text-white rounded-md  border outline outline-4 outline-dark-100 border-dark-900 bg-dark-800 hover:opacity-80 transition-all cursor-pointer",
              !existingAnimations && "finger-point"
            )}
            onClick={() => {
              console.log("clicked", cloned_url);
              toast.success("Opened your sandbox");
              if (cloned_url) setOpenModal(true);
            }}
          >
            Add to sandbox <span className="opacity-50 text-lg">+</span>
          </button>
          <button
            className={clsx(
              "font-semibold flex items-center align-center px-3 py-1 shadow-lg shadow-primary-400 text-dark rounded-md  border outline outline-4 outline-primary-400 border-primary-900 bg-primary-500  hover:opacity-80 transition-all cursor-pointer"
            )}
            onClick={() => {
              setWindowModal("install");
            }}
          >
            <span className="mr-1 flex justify-center items-center w-4 h-4 opacity-60">
              <svg
                class="w-7 text-dark-900"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M4 15v2a3 3 0 0 0 3 3h10a3 3 0 0 0 3-3v-2m-8 1V4m0 12-4-4m4 4 4-4"
                />
              </svg>
            </span>
            Install
          </button>
          x
        </div>
        {!existingAnimations && (
          <img
            alt={"Cloned version of your website"}
            className="w-2/3 self-center translate-y-[25px] rounded-t-sm border border-solid border-slate-300 shadow-md shadow-neutral-300"
            src={cloned_url + ".png"}
          />
        )}
      </div>
    </>
  );
}

export default Cloner;
