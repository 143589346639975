import React, { useState, useRef } from "react";

import "./AnimationsLibrary.css";

import { useParams, useLocation } from "react-router";
import useSWR from "swr";
import { SelectFilter } from "../SelectFilter/SelectFilter";
import { animations } from "./Animations";
import { useQuery, gql } from "@apollo/client";

const wait = (amount = 0) =>
  new Promise((resolve) => setTimeout(resolve, amount));

function addCSS(string) {
  document.head.appendChild(document.createElement("style")).innerHTML = string;
}
const fetcher = (url) => fetch(url).then((res) => res.json());

const GET_PRESET_ANIMATIONS = gql`
  query getPresetAnimations($app_id: ID!) {
    getPresetAnimations(app_id: $app_id) {
      id
      app_id
      preset_name
      animation_config
    }
  }
`;

function Builder() {
  const [name, setName] = useState(null);
  const [decorator, setDecorator] = useState(null);

  // const PreviewButton = useRef();

  function addToLibrary() {
    let animationCSS = `.rombo_${name.toLowerCase()} {
      position: relative;
      // animation-play-state: paused;
    }

.rombo_${name.toLowerCase()}:before {
content: '${decorator}';
position: absolute;
top: -25px;
left: 50px;
animation: DecoratorMove 5s linear forwards infinite;
}

@keyframes DecoratorMove {
0%, 100% {
  transform: translateX(0px) translateY(0px);
}
20% {
  transform: translateX(80px) translateY(0px);
}
40% {
  transform: translateX(80px) translateY(60px);
}
60% {
  transform: scale(1.1) translateX(-80px) translateY(60px);
}
70% {
  transform: translateX(-80px) translateY(0px);
}
}
`;

    fetch("https://6tqtmywcq4.execute-api.us-east-1.amazonaws.com/update", {
      method: "post",
      body: JSON.stringify({
        css: animationCSS,
        title: name,
        effect: `rombo_${name.toLowerCase()}`,
      }),
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        //Success code goes here
        alert("form submited");
      })
      .catch(function (err) {
        //Failure
        alert("Error");
      });
  }
  function previewCustomAnimation() {
    // let PreviewButton = document.querySelector("#preview-button");
    // PreviewButton.classList += `.rombo_${name.toLowerCase()}`;

    addCSS(`.rombo_${name.toLowerCase()} {
        position: relative;
        // animation-play-state: paused;
      }

.rombo_${name.toLowerCase()}:before {
  content: "${decorator}";
  position: absolute;
  top: -25px;
  left: 50px;
  animation: DecoratorMove 5s linear forwards infinite;
}

@keyframes DecoratorMove {
  0%, 100% {
    transform: translateX(0px) translateY(0px);
  }
  20% {
    transform: translateX(80px) translateY(0px);
  }
  40% {
    transform: translateX(80px) translateY(60px);
  }
  60% {
    transform: scale(1.1) translateX(-80px) translateY(60px);
  }
  70% {
    transform: translateX(-80px) translateY(0px);
  }
}
`);
    // PreviewButton.style.animationPlayState = "running";
  }
  return (
    <>
      <h1 class="m-4">Custom Animation Designer</h1>
      <div class="m-4 flex h-40 flex-row bg-slate-200 w-11/12 relative margin-auto">
        <div class="flex flex-col relative items-center justify-center p-2 m-2 rounded-lg bg-white border border-slate-300 flex-1">
          <button
            id="PreviewButton"
            class={`bg-blue-500 text-white px-6
            shadow
            cursor-not-allowed
            custom-animation
            ${name ? `rombo_${name.toLowerCase()}` : ""}
          `}
          >
            Previewer
          </button>
          <button class="flex text-sm rounded-full items-center justify-center text-gray-500 h-5 w-5 absolute bg-gray-200 bottom-0 right-2">
            ▶
          </button>
        </div>
        <div class="flex-1 flex flex-col justify-evenly relative">
          <label class="absolute top-0 right-2 cursor-pointer">Edit css</label>
          <input
            class="bg-slate-100 text-sm px-2 py-1 w-40 rounded-sm shadow-inset"
            placeholder="Animation name"
            onChange={(e) => setName(e.target.value)}
          />
          <input
            class="bg-slate-100 text-sm px-2 py-1 w-40 rounded-sm shadow-inset"
            placeholder="Decorator"
            onChange={(e) => setDecorator(e.target.value)}
          />
          <div class="flex">
            <button
              onClick={() => previewCustomAnimation()}
              class="m-1 text-sm w-max px-2 py-1 cursor-pointer bg-slate-500 text-white rounded-sm"
            >
              Preview
            </button>
            <button
              onClick={() => addToLibrary()}
              class="m-1 text-sm w-max px-2 py-1 cursor-pointer bg-slate-900 text-white rounded-sm"
            >
              Add animation to library
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

function AnimationsLibrary({ auth }) {
  const [preview, setPreview] = useState(null);
  const [trigger, setTrigger] = useState(null);
  const [sortedAnimations, setSortedAnimations] = useState([]);
  const { app_id } = useParams();

  const animatedElement = useRef(null);

  //   console.log(Animations);
  const { data: Animations, error } = useSWR(
    "https://rombo-base-animations.s3.eu-west-2.amazonaws.com/animations_v1.json",
    fetcher
  );

  const { data: presetAnimations } = useQuery(GET_PRESET_ANIMATIONS, {
    variables: { app_id: app_id },
  });

  console.log("presetAnimations", presetAnimations);

  const userDefinedPresets = presetAnimations?.getPresetAnimations || [];

  React.useEffect(() => {
    if (Animations) {
      setSortedAnimations(flattenAnimations(Animations));
    }
  }, [Animations]);
  flattenAnimations(Animations);

  React.useEffect(() => {
    if (trigger) {
      // Filter animations only for trigger
      let fileteredAnimations = flattenAnimations(Animations).filter(
        (el) => el.trigger === trigger.value
      );
      setSortedAnimations(fileteredAnimations);
    }
  }, [trigger]);
  async function previewEffect(effect) {
    if (Animations) {
      const EffectCSS = Object.keys(Animations)
        .reduce((a, k) => {
          return [...a, ...Animations[`${k}`]];
        }, [])
        .filter((el) => el.effect === effect)[0].css;
      addCSS(EffectCSS);
      setPreview(effect);
      let timePeriod = EffectCSS.match(/(\d{0,3})(?:s|ms)/, "g");
      let interval =
        timePeriod && timePeriod[1] > 10
          ? timePeriod[1]
          : timePeriod && timePeriod[0]
          ? 1000 * timePeriod[1]
          : timePeriod[1] === 0
          ? 1000
          : 1000;
      await wait(interval === 0 ? interval + 1000 : interval);
      setPreview(null);
    }
  }

  function clearFilters() {
    setTrigger(null);
    setSortedAnimations(flattenAnimations(Animations));
  }
  function flattenAnimations(animations) {
    let result = [];

    for (let key in animations) {
      animations[key].forEach((animation) => {
        result.push({
          trigger: key,
          ...animation,
        });
      });
    }

    return result;
  }

  const defaultDelay = 0;
  const defaultDuration = 750;

  const DefaultAnimationConfig = {
    globalDelay: 0,
    easing: "motion-ease-out-quart",
    loop: "none",
    properties: {
      scale: {
        delay: defaultDelay,
        duration: defaultDuration,
        startValue: 100,
      },
      translate: {
        delay: defaultDelay,
        duration: defaultDuration,
        startValue: 0,
        secondStartValue: 0,
      },
      opacity: {
        delay: defaultDelay,
        duration: defaultDuration,
        startValue: 100,
      },
      rotate: {
        delay: defaultDelay,
        duration: defaultDuration,
        startValue: 0,
      },
      blur: {
        delay: defaultDelay,
        duration: defaultDuration,
        startValue: 0,
      },
    },
  };

  function parsePresetConfig(config) {
    return {
      ...DefaultAnimationConfig,
      ...config,
      properties: {
        scale: {
          ...DefaultAnimationConfig.properties.scale,
          ...config.properties?.scale,
        },
        translate: {
          ...DefaultAnimationConfig.properties.translate,
          ...config.properties?.translate,
        },
        opacity: {
          ...DefaultAnimationConfig.properties.opacity,
          ...config.properties?.opacity,
        },
        rotate: {
          ...DefaultAnimationConfig.properties.rotate,
          ...config.properties?.rotate,
        },
        blur: {
          ...DefaultAnimationConfig.properties.blur,
          ...config.properties?.blur,
        },
      },
    };
  }

  const generateCSS = (config, tailwindClass) => {
    const {
      properties,
      loop: globalLoopType,
      easing,
      globalDelay,
    } = parsePresetConfig(config);
    const animations = [];
    const cssProperties = [];
    // Convert motion-ease-* to CSS easing
    const easingMap = {
      "motion-ease-linear": "linear",
      "motion-ease-in-out": "cubic-bezier(0.4, 0, 0.2, 1)",
      "motion-ease-out-cubic": "cubic-bezier(.215, .61, .355, 1)",
      "motion-ease-out-quart": "cubic-bezier(.165, .84, .44, 1)",
      "motion-ease-bounce":
        "linear(0, 0.004, 0.016, 0.035, 0.063, 0.098, 0.141 13.6%, 0.25, 0.391, 0.563, 0.765,1, 0.891 40.9%, 0.848, 0.813, 0.785, 0.766, 0.754, 0.75, 0.754, 0.766, 0.785,0.813, 0.848, 0.891 68.2%, 1 72.7%, 0.973, 0.953, 0.941, 0.938, 0.941, 0.953,0.973, 1, 0.988, 0.984, 0.988, 1)",
      "motion-ease-spring-smooth":
        "linear(0, 0.001 0.44%, 0.0045 0.94%, 0.0195 2.03%, 0.0446 3.19%, 0.0811 4.5%, 0.1598 6.82%, 0.3685 12.34%, 0.4693 15.17%, 0.5663, 0.6498 21.27%, 0.7215 24.39%, 0.7532 25.98%, 0.7829 27.65%, 0.8105, 0.8349 31.14%, 0.8573 32.95%, 0.8776 34.84%, 0.8964 36.87%, 0.9136 39.05%, 0.929 41.37%, 0.9421 43.77%, 0.9537 46.38%, 0.9636 49.14%, 0.9789 55.31%, 0.9888 62.35%, 0.9949 71.06%, 0.9982 82.52%, 0.9997 99.94%)",
      "motion-ease-spring-snappy":
        "linear(0, 0.0014, 0.0053 1.02%, 0.0126, 0.0227 2.18%, 0.0517 3.41%, 0.094 4.79%, 0.1865 7.26%, 0.4182 12.77%, 0.5246 15.46%, 0.6249, 0.7112, 0.7831 23.95%, 0.8146 25.4%, 0.844, 0.8699 28.45%, 0.8935, 0.9139 31.64%, 0.932, 0.9473, 0.9601 36.65%, 0.9714 38.47%, 0.9808 40.35%, 0.9948 44.49%, 1.0031 49.43%, 1.0057 53.35%, 1.0063 58.14%, 1.0014 80.78%, 1.0001 99.94%)",
      "motion-ease-spring-bouncy":
        "linear(0, 0.0018, 0.0069, 0.0151 1.74%, 0.0277 2.4%, 0.062 3.7%, 0.1115 5.15%, 0.2211 7.77%, 0.4778 13.21%, 0.5912 15.75%, 0.6987 18.44%, 0.7862 20.98%, 0.861 23.59%, 0.8926, 0.9205, 0.945 27.51%, 0.9671 28.89%, 0.9868, 1.003 31.79%, 1.0224 34.11%, 1.0358 36.58%, 1.0436 39.27%, 1.046 42.31%, 1.0446 44.71%, 1.0406 47.47%, 1.0118 61.84%, 1.0027 69.53%, 0.9981 80.49%, 0.9991 99.94%)",
      "motion-ease-spring-bouncier":
        "linear(0, 0.0023, 0.0088, 0.0194 1.59%, 0.035 2.17%, 0.078 3.33%, 0.1415 4.64%, 0.2054 5.75%, 0.2821 6.95%, 0.5912 11.45%, 0.7205 13.43%, 0.8393 15.45%, 0.936 17.39%, 0.9778, 1.015, 1.0477, 1.0759, 1.0998 22.22%, 1.1203, 1.1364, 1.1484 25.26%, 1.1586 26.61%, 1.1629 28.06%, 1.1613 29.56%, 1.1537 31.2%, 1.1434 32.6%, 1.1288 34.19%, 1.0508 41.29%, 1.0174 44.87%, 1.0025 46.89%, 0.9911 48.87%, 0.9826 50.9%, 0.9769 53.03%, 0.9735 56.02%, 0.9748 59.45%, 0.9964 72.64%, 1.0031 79.69%, 1.0042 86.83%, 1.0008 99.97%)",
      "motion-ease-spring-bounciest":
        "linear(0, 0.0032, 0.0131, 0.0294, 0.0524, 0.0824, 0.1192 1.54%, 0.2134 2.11%, 0.3102 2.59%, 0.4297 3.13%, 0.8732 4.95%, 1.0373, 1.1827 6.36%, 1.2972 7.01%, 1.3444, 1.3859, 1.4215, 1.4504, 1.4735, 1.4908, 1.5024, 1.5084 9.5%, 1.5091, 1.5061, 1.4993, 1.4886, 1.4745, 1.4565 11.11%, 1.4082 11.7%, 1.3585 12.2%, 1.295 12.77%, 1.0623 14.64%, 0.9773, 0.9031 16.08%, 0.8449 16.73%, 0.8014, 0.7701 17.95%, 0.7587, 0.7501, 0.7443, 0.7412 19.16%, 0.7421 19.68%, 0.7508 20.21%, 0.7672 20.77%, 0.7917 21.37%, 0.8169 21.87%, 0.8492 22.43%, 0.9681 24.32%, 1.0114, 1.0492 25.75%, 1.0789 26.41%, 1.1008, 1.1167, 1.1271, 1.1317 28.81%, 1.1314, 1.1271 29.87%, 1.1189 30.43%, 1.1063 31.03%, 1.0769 32.11%, 0.9941 34.72%, 0.9748 35.43%, 0.9597 36.09%, 0.9487, 0.9407, 0.9355, 0.933 38.46%, 0.9344 39.38%, 0.9421 40.38%, 0.9566 41.5%, 0.9989 44.12%, 1.0161 45.37%, 1.029 46.75%, 1.0341 48.1%, 1.0335 49.04%, 1.0295 50.05%, 1.0221 51.18%, 0.992 55.02%, 0.9854 56.38%, 0.9827 57.72%, 0.985 59.73%, 1.004 64.67%, 1.0088 67.34%, 1.0076 69.42%, 0.9981 74.28%, 0.9956 76.85%, 0.9961 79.06%, 1.0023 86.46%, 0.999 95.22%, 0.9994 100%)",
    };

    const getEasing = (property) => {
      // Use smooth spring for filter, opacity, and color animations
      if (property === "opacity" || property === "blur") {
        return easing === "motion-ease-spring-bouncier"
          ? easingMap["motion-ease-spring-smooth"]
          : easingMap[easing];
      }
      return easingMap[easing];
    };
    const easingsMultipliers = {
      "motion-ease-spring-smooth": 1.66,
      "motion-ease-spring-snappy": 1.66,
      "motion-ease-spring-bouncy": 1.66,
      "motion-ease-spring-bouncier": 2.035,
      "motion-ease-spring-bounciest": 5.285,
      "motion-ease-bounce": 2,
    };

    const getMultiplier = (property) => {
      // For opacity and blur, use smooth spring multiplier if bouncier spring is selected
      if (
        (property === "opacity" || property === "blur") &&
        easing === "motion-ease-spring-bouncier"
      ) {
        return easingsMultipliers["motion-ease-spring-smooth"] ?? 1;
      }
      return easingsMultipliers[easing] ?? 1;
    };

    // Scale
    if (properties.scale.startValue !== 100) {
      animations.push(
        `@keyframes scale-animation {${
          globalLoopType === "loop" || globalLoopType === "loop-once"
            ? `
  0%, 100% { scale: 1; }
  50% { scale: ${properties.scale.startValue / 100}; }
`
            : `
  from { scale: ${properties.scale.startValue / 100}; }
  to { scale: 1; }
`
        }}`
      );
      cssProperties.push(
        `scale-animation ${Math.round(
          properties.scale.duration * getMultiplier("scale")
        )}ms ${getEasing("scale")} ${Math.round(
          properties.scale.delay + globalDelay
        )}ms ${
          globalLoopType === "loop"
            ? "infinite"
            : globalLoopType === "loop-once"
            ? "1"
            : "forwards"
        }`
      );
    }

    // Translate
    if (
      properties.translate.startValue !== 0 ||
      properties.translate.secondStartValue !== 0
    ) {
      animations.push(`
@keyframes translate-animation {${
        globalLoopType === "loop" || globalLoopType === "loop-once"
          ? `
  0%, 100% { translate: 0% 0%; }
  50% { translate: ${properties.translate.startValue}% ${properties.translate.secondStartValue}%; }
`
          : `
  from { translate: ${properties.translate.startValue}% ${properties.translate.secondStartValue}%; }
  to { translate: 0% 0%; }
`
      }}`);
      cssProperties.push(
        `translate-animation ${Math.round(
          properties.translate.duration * getMultiplier("translate")
        )}ms ${getEasing("translate")} ${Math.round(
          properties.translate.delay + globalDelay
        )}ms ${
          globalLoopType === "loop"
            ? "infinite"
            : globalLoopType === "loop-once"
            ? "1"
            : "forwards"
        }`
      );
    }

    // Opacity
    if (properties.opacity.startValue !== 100) {
      animations.push(`
@keyframes opacity-animation {${
        globalLoopType === "loop" || globalLoopType === "loop-once"
          ? `
  0%, 100% { opacity: 1; }
  50% { opacity: ${properties.opacity.startValue / 100}; }
`
          : `
  from { opacity: ${properties.opacity.startValue / 100}; }
  to { opacity: 1; }
`
      }}`);
      cssProperties.push(
        `opacity-animation ${Math.round(
          properties.opacity.duration * getMultiplier("opacity")
        )}ms ${getEasing("opacity")} ${Math.round(
          properties.opacity.delay + globalDelay
        )}ms ${
          globalLoopType === "loop"
            ? "infinite"
            : globalLoopType === "loop-once"
            ? "1"
            : "forwards"
        }`
      );
    }

    // Rotate
    if (properties.rotate.startValue !== 0) {
      animations.push(`
@keyframes rotate-animation {${
        globalLoopType === "loop" || globalLoopType === "loop-once"
          ? `
  0%, 100% { rotate: 0deg; }
  50% { rotate: ${properties.rotate.startValue}deg; }
`
          : `
  from { rotate: ${properties.rotate.startValue}deg; }
  to { rotate: 0deg; }
`
      }}`);
      cssProperties.push(
        `rotate-animation ${Math.round(
          properties.rotate.duration * getMultiplier("rotate")
        )}ms ${getEasing("rotate")} ${Math.round(
          properties.rotate.delay + globalDelay
        )}ms ${
          globalLoopType === "loop"
            ? "infinite"
            : globalLoopType === "loop-once"
            ? "1"
            : "forwards"
        }`
      );
    }

    // Blur
    if (properties.blur.startValue !== 0) {
      animations.push(`
@keyframes blur-animation {${
        globalLoopType === "loop" || globalLoopType === "loop-once"
          ? `
  0%, 100% { filter: blur(0); }
  50% { filter: blur(${properties.blur.startValue}px); }
`
          : `
  from { filter: blur(${properties.blur.startValue}px); }
  to { filter: blur(0); }
`
      }}`);
      cssProperties.push(
        `blur-animation ${Math.round(
          properties.blur.duration * getMultiplier("blur")
        )}ms ${getEasing("blur")} ${Math.round(
          properties.blur.delay + globalDelay
        )}ms ${
          globalLoopType === "loop"
            ? "infinite"
            : globalLoopType === "loop-once"
            ? "1"
            : "forwards"
        }`
      );
    }

    return `${animations.join("")}
  
.${tailwindClass} {
  animation: ${cssProperties.join(",\n")};
}`;
  };

  const handleAnimationClick = (animation, element) => {
    const css = generateCSS(animation.config, animation.tailwindClass);
    addCSS(css);
    console.log(css);

    // Reset animation state on the clicked element
    if (element) {
      element.classList.remove(animation.tailwindClass);
      void element.offsetWidth; // Trigger reflow
      element.classList.add(animation.tailwindClass);
    }
  };

  if (!Animations) return "";

  return (
    <section className="animations-library">
      <div className="animations-grid flex flex-col w-3/4 mx-auto">
        <h1 className="text-2xl font-bold mb-4 opacity-60 pt-6">
          Rombo Preset Animations
        </h1>
        <div className="grid grid-cols-4 gap-4">
          {animations.map((animation, index) => (
            <div
              key={index}
              onClick={(e) => {
                e.stopPropagation();
                handleAnimationClick(animation, e.currentTarget);
              }}
              className="cursor-pointer hover:bg-gray-100/20 animation-item border border-black/20 bg-gray-100/5 outline outline-2 outline-black/5 px-2 py-1 rounded-lg flex flex-row"
            >
              <div
                className="translate-y-[1px] opacity-50 animation-icon scale-75 pr-2"
                dangerouslySetInnerHTML={{ __html: animation.svg_icon }}
              />
              <span className="animation-title font-semibold">
                {animation.title}
              </span>
            </div>
          ))}
        </div>

        {userDefinedPresets.length > 0 && (
          <>
            <h1 className="text-2xl font-bold mb-4 opacity-60 pt-12">
              Custom Animation Presets
            </h1>
            <div className="grid grid-cols-4 gap-4">
              {userDefinedPresets.map((preset, index) => (
                <div
                  key={index}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleAnimationClick(
                      {
                        title: preset.preset_name,
                        config: JSON.parse(preset.animation_config),
                        tailwindClass: `preset-${preset.id}`,
                      },
                      e.currentTarget
                    );
                  }}
                  className="cursor-pointer hover:bg-gray-100/20 animation-item border border-black/20 bg-gray-100/5 outline outline-2 outline-black/5 px-2 py-1 rounded-lg flex flex-row"
                >
                  <div
                    className="translate-y-[1px] opacity-50 animation-icon scale-75 pr-2"
                    dangerouslySetInnerHTML={{
                      __html:
                        '<svg width="19" height="18" viewBox="0 0 19 18" xmlns="http://www.w3.org/2000/svg"><path d="M17.9785 3.02734V14.9609C17.9785 16.9727 16.9629 17.9785 14.9121 17.9785H3.06641C1.02539 17.9785 0 16.9727 0 14.9609V3.02734C0 1.01562 1.02539 0 3.06641 0H14.9121C16.9629 0 17.9785 1.01562 17.9785 3.02734ZM3.59375 8.98438C3.59375 11.9629 6.00586 14.375 8.99414 14.375C11.9727 14.375 14.3848 11.9629 14.3848 8.98438C14.3848 6.00586 11.9727 3.58398 8.99414 3.58398C6.00586 3.58398 3.59375 6.00586 3.59375 8.98438Z"/></svg>',
                    }}
                  />
                  <span className="animation-title font-semibold">
                    {preset.preset_name}
                  </span>
                </div>
              ))}
            </div>
          </>
        )}
      </div>
    </section>
  );
}

export default AnimationsLibrary;
