import { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import { GET_APP } from "../../Queries";
import triggerStripeCheckout from "../../Helpers/stripeTrigger";

let pageViewLimit = 1000;

function calculatePageViewRemaining(billingPlan, pageViews) {
  if (billingPlan === "unlimited") {
    return Infinity;
  }
  return pageViewLimit - pageViews > 0 ? pageViewLimit - pageViews : "No";
}

function UsageTracker({ appId, auth, setPricingModal }) {
  const {
    data: appData,
    loading: appLoading,
    error: appError,
  } = useQuery(GET_APP, {
    variables: { app_id: appId },
  });

  if (appLoading || !appData) {
    return <></>;
  }

  if (appData?.getApp) {
    const { getApp } = appData;
    // getApp.page_views = 0;

    if (getApp?.billing_plan === "unlimited") {
      return (
        <aside class="mx-3 my-4 px-0.5 cursor-pointer py-0.5 items-center flex rounded-sm flex-row align-center">
          <svg
            class="w-4 mr-1 text-gray-400 dark:text-white"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="none"
            viewBox="0 0 24 24"
          >
            <path
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="m13.46 8.291 3.849-3.849a1.5 1.5 0 0 1 2.122 0l.127.127a1.5 1.5 0 0 1 0 2.122l-3.84 3.838a4 4 0 0 0-2.258-2.238Zm0 0a4 4 0 0 1 2.263 2.238l3.662-3.662a8.961 8.961 0 0 1 0 10.27l-3.676-3.676m-2.25-5.17 3.678-3.676a8.961 8.961 0 0 0-10.27 0l3.662 3.662a4 4 0 0 0-2.238 2.258L4.615 6.863a8.96 8.96 0 0 0 0 10.27l3.662-3.662a4 4 0 0 0 2.258 2.238l-3.672 3.676a8.96 8.96 0 0 0 10.27 0l-3.662-3.662a4.001 4.001 0 0 0 2.238-2.262m0 0 3.849 3.848a1.5 1.5 0 0 1 0 2.122l-.127.126a1.499 1.499 0 0 1-2.122 0l-3.838-3.838a4 4 0 0 0 2.238-2.258Zm.29-1.461a4 4 0 1 1-8 0 4 4 0 0 1 8 0Zm-7.718 1.471-3.84 3.838a1.5 1.5 0 0 0 0 2.122l.128.126a1.5 1.5 0 0 0 2.122 0l3.848-3.848a4 4 0 0 1-2.258-2.238Zm2.248-5.19L6.69 4.442a1.5 1.5 0 0 0-2.122 0l-.127.127a1.5 1.5 0 0 0 0 2.122l3.849 3.848a4 4 0 0 1 2.238-2.258Z"
            />
          </svg>
          Rombo Pro Plan unlocked
        </aside>
      );
    }
    let remainingPercentage = calculatePageViewRemaining(
      getApp.billing_plan,
      getApp.page_views
    );

    let percentageUsed = (
      (parseInt(getApp.page_views) * 100) /
      pageViewLimit
    ).toFixed(0);
    return (
      // <div class="mx-3 my-2 px-3 flex bg-white shadow-sm border border-solid border-slate-200 rounded-sm flex-col align-middle">
      <button
        class="mb-3 mx-2 flex flex-col align-center items-center text-sm shadow-sm shadow-primary-400 bg-primary-500 border border-solid mt-4 border-primary-700 px-2 py-0.5 rounded-sm hover:bg-primary-300"
        onClick={
          () => setPricingModal(true)
          // async () => {
          // await triggerStripeCheckout(
          //   appId,
          //   getApp.billing_email
          //     ? getApp.billing_email
          //     : auth.attributes.email
          // );
        }
      >
        <span className="opacity-50">
          Unlock timeline customization, syncing with Figma, and more
        </span>
        <hr className="w-full border-primary-700 my-2" />
        <span>Upgrade to Rombo Pro</span>
      </button>
      // </div>
    );
  }
  return <></>;
}

export default UsageTracker;
